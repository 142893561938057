//colors
$color_white: #fff;
$color_black: #000;
$color_grey_1: #343A40;
$color_grey_2: #868E96;
$color_grey_3: #495057;
$color_grey_4: #D0D0D0;
$color_grey_5: #F1F3F5;
$color_grey_6: #e9ecef;
$color_grey_7: #9D9D9D;
$color_grey_8: #a3a9af;
$color_grey_9: #CED4DA;
$color_grey_10: #ADB5BD;
$color_royal_blue: #1864AB;
$color_blue_1: #e7f5fd;
$color_blue_2: #519dde;
$color_blue_3: #1c64ab;
$color_blue_4: #f8fcfe;
$color_blue_5: #228BE6;
$color_blue_6: #D0EBFF;
$color_blue_7: #E7F5FF;
$color_blue_8: #a5d8ff;
$color_red_1: #D9480F;
$color_light_black: #00000029;

// $color_grey: #343434;
// $color_green: #0E9AA7;
// $color_red: #ff0000;
// $color_red_1: #FF671733;
// $color_red_2: #FF6717;
// $color_red_3: #E60000;
$color_light_grey_1: #808080;
// $color_light_grey_3: #636261;
// $color_light_grey_4: #E8E8E8;
// $color_light_grey_5: #F0F0F0;
// $color_light_grey_6: #A7A7AD;
// $color_light_grey_7: #FFFFFFB1;
// $color_blue: #156dd9;
// $color_blue_1: #006DD9;
// $color_blue_2: #E5F2FF;
// $color_blue_3: #F5F6F8;
// $color_blue_4: #006DD90D;

$textbox_hover_border_color: #156dd9;
$form_field_height: 40px;

$text_box_alignment: 4px;

//menu
$header_height: 60px;
$menu_icon_width: 25px;
$menu_icon_height: 16px;
$menu_icon_margin: 10px;
$guest_table_header_height: 70px;

$width_100: 100%;
$width_80: 80%;
$zero: 0;

//font
$font_weight_500: 500;
$font_size_10: 10px;
$font_size_12: 12px;
$font_size_13: 13px;
$font_size_14: 14px;
$font_size_16: 16px;
$font_size_18: 18px;
$font_size_20: 20px;
$font_size_24: 24px;
$font_size_26: 26px;
$font_size_30: 30px;

//margin
$margin_3: 3%;
$margin_5: 5%; //10px 
$margin_10: 10%; //20px 
$margin_15: 15%;
$margin_30: 30%;
$margin_10_px: 10px;
$margin_15_px: 15px;
$margin_20_px: 20px;
$margin_25_px: 25px;

//padding
$padding_3: 3%;
$padding_5: 5%;
$padding_10: 10%;
$padding_15: 15%;
$padding_5_px: 5px;
$padding_10_px: 10px;
$padding_15_px: 15px;
$padding_20_px: 20px;
$padding_25_px: 25px;
$padding_30_px: 30px;

//fonts
$roboto-helvetica: Roboto, "Helvetica Neue", sans-serif;
