@import "variables";
@import "mixins";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-style: normal;
  box-sizing: inherit;
}

.full-modal-component {
  width: 100vw !important;
  max-width: 100vw !important;
  max-height: calc(100vh - 60px) !important;
  height: calc(100vh - 60px) !important;
  align-self: flex-end;
}

.percent90-modal-component {
  max-width: 90vw !important;
  width: 100% !important;
  max-height: 90vh !important;
  height: 100vh !important;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  height: 100%;
  font-family: $roboto-helvetica;
  background: $color_blue_4;
}

app-header {
  position: sticky;
  top: 0;
  z-index: 9;
}

.mat-dialog-container {
  padding: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;

  &-color {
    pointer-events: none;
    opacity: 1;
  }
}

.color_red {
  color: $color_red_1;
}

.height_100 {
  height: 100%;
}

.height_100vh {
  height: 100vh !important;
}

.width_100 {
  width: 100%;
}

.width_50 {
  width: 50%;
}

.width_65 {
  width: 65%;
}

.width_30 {
  width: 30%;
}

.width_45 {
  width: 45%;
}

.width_23 {
  width: 23%;
}

.width_20 {
  width: 20%;
}

.width_18 {
  width: 18%;
}

.width_15 {
  width: 15%;
}

.width_11 {
  width: 11%;
}

.width_10 {
  width: 10%;
}

.width_6 {
  width: 6%;
}

.margin_right_5 {
  margin-right: 5%;
}

.margin_right_15 {
  margin-right: 15%;
}

.margin_right_20 {
  margin-right: 20%;
}

.font_weight_500 {
  font-weight: $font_weight_500;
}

.font_10 {
  font-size: $font_size_10;
}

.position_relative {
  position: relative;
}

.cursor_pointer {
  cursor: pointer;
}

.display_none {
  display: none !important;
}

.visibility_hidden {
  visibility: hidden;
}

button {
  cursor: pointer;
}

.align_center {
  text-align: center;
}

.clear_filter_section {
  min-width: 90px;

  div {
    font-size: $font_size_13;
    color: $color_blue_5;
  }

  &-hidden {
    visibility: hidden;
  }
}

.overlay {
  position: absolute;
  background: #85898c;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0.7;
}

// code to add hover color & active color once visited for all textbox
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $color_blue_5;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: $color_blue_5;
}

.mat-calendar-body-selected {
  background-color: $color_blue_5;
}

.mat-datepicker-toggle-active {
  color: $color_blue_5 !important;
}

.guest-detail-page,
#chatPage,
.chat_user_content {
  .chat_container {
    .chat_section {
      background: $color_white;
      border: 1px solid $color_grey_6;
      border-radius: 5px;
    }

    .chat_header {
      // box-shadow: 0px 0px 6px #343a401a;
      border: 1px solid $color_grey_6;
      opacity: 1;
      background: $color_white;
      padding: $padding_10_px $padding_15_px;
      min-height: 60px;
      height: 60px;

      @include for-phones {
        max-height: 14%;
      }
    }

    .create_task {
      font-size: $font_size_13;
      color: $color_grey_3;
    }

    .create_task_button {
      font-size: $font_size_13;
      border: 1px solid $color_blue_5;
      border-radius: 5px;
      color: $color_blue_5;
      height: 34px;
      padding: $zero $padding_10_px;
      width: max-content;
      cursor: pointer;
    }

    .filter_section {
      .filter_2 {
        width: $width_100;
        font-size: $font_size_13;

        &.disabled {
          .mat-form-field-outline {
            background: #d4d8dc;
            border-radius: 5px;
          }
        }
      }
    }

    .chat_body {
      // height: calc(100% - 50px);

      &_container {
        width: $width_100;
        bottom: 0;
        position: relative;
      }

      .chat-content {
        overflow-y: auto;
        padding: $padding_10_px;
        height: calc(100vh - 245px);
      }

      .chat_date {
        font-size: $font_size_12;
        color: $color_grey_2;
        position: relative;
        font-weight: 300;

        .hr_line {
          border-bottom: 1px solid $color_grey_5;
          position: absolute;
          bottom: 7px;
          width: $width_100;
        }

        .time_text {
          padding: $zero $padding_15_px;
          z-index: 0;
          background: #fff;
        }
      }

      .chat_time {
        color: $color_grey_2;
        font-size: $font_size_12;
        font-weight: 300;
      }

      .message {
        &.left {
          margin-right: 8%;

          .inner_content {
            background: $color_grey_5;
            color: $color_grey_3;
            max-width: 78%;
          }
        }

        &.right {
          margin-left: 8%;

          .inner_content {
            background: $color_blue_6;
            color: $color_grey_3;
            max-width: 78%;
          }
        }

        .inner_content {
          margin-right: $margin_10_px;
          font-size: $font_size_14;
          padding: $padding_15_px;
          border-radius: 5px;
        }
      }
    }

    .sticky-footer {
      position: sticky;
      width: $width_100;
      border-top: 1px solid $color_grey_6;
      padding-top: $padding_20_px;
      background: $color_blue_4;

      mat-icon {
        transform: scaleX(1.5) scaleY(1.8);
        cursor: pointer;
      }

      mat-form-field {
        width: 92%;
      }

      ::ng-deep .mat-form-field {
        font-size: $font_size_14;
        color: $color_grey_3;
        background: $color_white;
        height: 48px;

        .mat-form-field-wrapper {
          padding-bottom: $zero;
          margin: $zero;
        }

        .mat-form-field-infix {
          position: relative;
          bottom: $text_box_alignment;
        }
      }

      ::ng-deep textarea.mat-input-element {
        padding: $zero;
      }

      ::ng-deep .text_area .mat-form-field-outline {
        background: $color_white;
      }
    }
  }
}

.request_count {
  background: $color_red_1;
  border-radius: 4px;
  color: $color_blue_7;
  width: 18px;
  font-size: 10px;
  height: 18px;
}

.mat-tab-list {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

// radio-buttons styling
::ng-deep {
  .mat-ripple-element,
  .mat-radio-inner-circle {
    background-color: $color_blue_5 !important;
  }

  .mat-radio-checked {
    .mat-radio-inner-circle {
      transform: scale(1) !important;
      position: relative;

      &:before,
      &:after {
        content: "";
        background: #fff;
        position: absolute;
        width: 2px;
      }

      &:before {
        height: 7px;
        top: 9px;
        left: 5px;
        transform: rotate(-45deg);
      }

      &:after {
        height: 12px;
        top: 4px;
        left: 11px;
        transform: rotate(45deg);
      }
    }

    .mat-radio-outer-circle {
      border-color: $color_blue_5 !important;
    }
  }
}

//checkboxes styling
::ng-deep {
  .mat-checkbox-checked {
    .mat-ripple-element {
      background: $color_blue_5 !important;
    }

    .mat-checkbox-background {
      background-color: transparent !important;

      path {
        stroke: $color_blue_5 !important;
      }
    }

    .mat-checkbox-frame {
      border-color: $color_blue_5;
    }
  }
}

::ng-deep
  .cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: $color_blue_5 !important;
}

// scrollbar styling
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a9a8a8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}

@include for-phones {
  ::-webkit-scrollbar {
    height: 4px;
  }
}

// styling mat-menu
::ng-deep .mat-menu-panel {
  min-height: unset !important;

  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
  }

  button.mat-menu-item {
    font-size: 13px;
    color: $color_grey_3;
    padding: 0 16px !important;
  }
}

.custom_dialog_container_ {
  transform: translateY(15px);
  max-width: 100% !important;
}

.share-link_container {
  transform: translateY(3%);
  max-width: 100% !important;

  &.border-top {
    border-top: 3px solid $color_blue_5;
    border-radius: 5px;
  }
}

::ng-deep .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.42);
}

::ng-deep .mat-snack-bar-container {
  background: $color_blue_7;
  border-top: 5px solid $color_blue_5;
}

// styling globally inputs and mat-options
::ng-deep {
  .mat-option {
    padding: 0 16px !important;

    @include for-phones {
      // TODO: return here
      //max-width: 170px !important;
    }
  }

  .mat-form-field-wrapper {
    width: 100%;
    padding-bottom: $zero;

    * {
      font-size: 13px;
      letter-spacing: 0.2px !important;
    }

    .mat-form-field-infix {
      padding: 0.35em $zero 0.8em !important;
    }

    .mat-form-field-prefix {
      top: 0.4em !important;
    }

    .mat-form-field-prefix .mat-icon-button {
      width: auto !important;
      height: auto !important;
      margin-right: 5px !important;
    }

    mat-datepicker-toggle {
      .mat-button-ripple.mat-ripple,
      .mat-button-focus-overlay {
        transform: scale(1.5);
      }
    }
  }
}
::ng-deep .filter_Box {
  .filter_section {
    position: relative;
    width: 180px;
    .mat-form-field-underline,
    .mat-form-field-type-mat-select .mat-form-field-label,
    .mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      display: none !important;
    }
    .mat-form-field-infix,
    .mat-form-field-label-wrapper {
      width: auto;
      position: unset;
      padding: 0px !important;
      border: none;
    }
    .mat-select-value {
      display: block;
      overflow: visible;
      max-width: fit-content;
    }
    .mat-form-field-hide-placeholder {
      .mat-select-placeholder {
        -webkit-text-fill-color: #868e96;
        color: #868e96;
      }
    }
    .mat-form-field-wrapper {
      margin: 0 !important;
      border: 1px solid #ced4da;
      border-radius: 5px;
      padding: 0;
      background: #fff;
    }
    .mat-form-field {
      height: auto !important;
    }
    .mat-select-trigger {
      padding: 12px 15px;
      padding-right: 25px;
      width: 100%;
    }
    .mat-select-arrow-wrapper {
      display: none;
    }
    img.downArrow {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
      z-index: 0;
    }
    &.servicetype {
      // min-width: 139px;
    }
    &.paymentstatus {
      // min-width: 157px;
    }
    &.transactionStatus {
      // min-width: 175px;
    }
    &.sortSection {
      // min-width: 106px;
    }
    .filter_2 {
      .mat-form-field-wrapper {
        border: none;
        background: transparent;
        .mat-form-field-flex {
          padding: 11px;
          margin: 0;
        }
        .mat-form-field-infix {
          position: relative;
          .mat-form-field-label-wrapper {
            position: absolute;
          }
        }
      }
    }
  }
  .button-section {
    padding: 13px 10px;
    height: auto;
  }
  &.addGuestBox {
    .filter_section {
      width: 100%;
    }
  }
}
.filter_searchBox {
  width: 100%;
  max-width: 368px !important;
  .filter_section {
    width: 100%;
    .filter_1 {
      width: 100%;
    }
    .search_button {
      top: 4px;
    }
    .mat-form-field-infix {
      margin: 0 !important;
      padding: 7px 20px 13px 0 !important;
    }
    .mat-form-field-label-wrapper {
      padding: 0 !important;
    }
    .mat-form-field-wrapper,
    .mat-form-field-flex {
      margin: 0 !important;
    }
  }
}

.fc .fc-timegrid-slot {
  height: 100px !important;
}

/*** Intro JS CSS start for steps */

// .introjs-overlay {
//   transition: all 0.3s ease-out;
//   background-color: rgba(255, 255, 255, 1);
//   opacity: 0.7;
//   z-index: 1 !important;
// }

.intro-step-1 {
  max-width: 767px !important;
  width: 767px !important;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  border-radius: 6px !important;
  box-shadow: 0px 3px 6px #1864ab52 !important;

  .introjs-tooltip-header,
  .introjs-helperNumberLayer {
    display: none;
  }
  .intro-banner {
    width: 100%;
    max-width: 272px;
    margin: 40px auto 30px;
    img {
      width: 100%;
    }
  }
  .introjs-tooltipbuttons {
    display: flex;
    justify-content: end;
    gap: 135px;
    border: none;
    padding: 40px;
    a {
      max-width: 197px;
      width: 100%;
      text-align: center;
      background-color: transparent;
      padding: 10px;
      font-size: 0;
      line-height: 19px;
      border-radius: 5px;
      border: 1px solid transparent;
      position: relative;
      &.introjs-prevbutton {
        border: 1px solid #ced4da;
        color: #495057;
        &:before {
          content: "Skip Tour 12";
          position: absolute;
          font-size: 14px;
          line-height: 19px;
          color: #495057;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.introjs-nextbutton {
        border: 1px solid #1864ab;
        background-color: #1864ab;
        color: #fff;

        &:before {
          content: "Start Tour Now";
          position: absolute;
          font-size: 14px;
          line-height: 19px;
          color: #fff;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  .step1_title {
    color: #1864ab;
    text-align: center;
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 21px;
    font-weight: 500;
  }
  p {
    text-align: center;
    padding: 0 30px 10px;
    font-size: 14px;
    line-height: 21px;
    max-width: 605px;
    margin: auto;
  }
  .btnPart {
    display: flex;
    justify-content: center;
    gap: 15px;
    border: none;
    padding: 40px;
    position: absolute;
    left: 135px;
    max-width: 197px;
    width: 100%;
    a {
      max-width: 197px;
      width: 100%;
      text-align: center;
      background-color: transparent;
      padding: 10px;
      font-size: 14px;
      line-height: 19px;
      border-radius: 5px;
      font-weight: 500;
      border: 1px solid transparent;
      position: relative;
      cursor: pointer;
      &.intro-skip {
        border: 1px solid #ced4da;
        color: #495057;
      }
      &.intro-start {
        border: 1px solid #1864ab;
        background-color: #1864ab;
        color: #fff;
      }
    }
  }
  .introjs-tooltiptext {
    padding: 0;
  }
  // .introjs-button.introjs-prevbutton.introjs-disabled {
  //   visibility: hidden !important;
  // }
  .introjs-button.introjs-prevbutton {
    display: none !important;
  }
}

.introjs-fixedTooltip {
  // border: none !important;
  // box-shadow: none !important;
  .intro-step-2 {
    background-color: #fff;
    box-shadow: 0px 3px 6px #1864ab52;
    border: 0.5px solid #1864ab;
    .introjs-tooltip-title {
      font-size: 14px;
      color: #1864ab;
      letter-spacing: 0.7px;
      font-family: "Roboto";
      font-weight: 700;
      line-height: 19px;
    }
    .introjs-tooltiptext {
      font-size: 13px;
      color: #495057;
      padding: 20px !important;
      letter-spacing: 0.65px;
      font-family: "Roboto";
      line-height: 18px;
    }

    .introjs-arrow.left {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-right: 8px solid #1864ab;
      left: -13px;
      top: 85px;
    }
    .introjs-arrow {
      &::after {
        content: "";
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fff;
        top: -8px;
        left: 1px;
      }
    }
  }

  .introjs-tooltip.intro-step-2 {
    left: 198px !important;
    width: 340px;
    top: -72px;
    .introjs-button:focus {
      box-shadow: none;
    }
    .introjs-skipbutton {
      color: #ced4da;
      font-weight: 300;
      padding-bottom: 0 !important;
      font-size: 28px;
      padding: 0;
      line-height: 1;
    }
    .introjs-tooltip-header {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 20px;
    }
  }
}

.introjs-disableInteraction {
  border: none !important;
}

.introjs-tooltipbuttons {
  border-top: none;
  padding: 0 0 20px 0;
  text-align: right;
  white-space: nowrap;
  display: flex;
  gap: 18px;
  justify-content: end;
  a {
    background-color: transparent;
    text-shadow: none;
    line-height: 18px;
    font-weight: 500;
    border-radius: 5px;
  }
  .introjs-nextbutton {
    background-color: #a5d8ff;
    border: 1px solid #a5d8ff;
    color: #1864ab;
  }
}

.introjs-helperLayer {
  // border: none !important;
  // box-shadow: none !important;
}
.intro-step-3 {
  background-color: #fff;
  box-shadow: 0px 3px 6px #1864ab52;
  border: 0.5px solid #1864ab;
  right: 30px;
  left: auto !important;
  width: 300px;
  .introjs-tooltip-title {
    font-size: 14px;
    color: #1864ab;
    letter-spacing: 0.7px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 19px;
  }
  .introjs-tooltiptext {
    font-size: 13px;
    color: #495057;
    padding: 20px !important;
    letter-spacing: 0.65px;
    font-family: "Roboto";
    line-height: 18px;
  }
  .introjs-arrow.top-right {
    top: -13px;
    left: auto;
    margin-left: -5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #1864ab;
    right: 20px;
  }
  .introjs-arrow {
    &::after {
      content: "";
      position: absolute;
      border-left: 7px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
      right: -7.9px;
      top: 1px;
    }
  }
  .introjs-button:focus {
    box-shadow: none;
  }
  .introjs-skipbutton {
    color: #ced4da;
    font-weight: 300;
    padding-bottom: 0 !important;
    font-size: 28px;
    padding: 0;
    line-height: 1;
  }
  .introjs-tooltip-header {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }
  .introjs-nextbutton {
    font-size: 0px;
    position: relative;
    &::after {
      content: "Okay";
      font-size: 14px;
    }
  }
  .introjs-tooltipbuttons {
    a {
      padding: 0.5rem 1rem 0rem 1rem;
    }
  }
}

.intro-step-4 {
  background-color: #fff;
  box-shadow: 0px 3px 6px #1864ab52;
  border: 0.5px solid #1864ab;
  left: 0;
  right: 0;
  width: 328px;
  max-width: 328px;
  height: 244px;
  .introjs-tooltip-title {
    font-size: 14px;
    color: #1864ab;
    letter-spacing: 0.7px;
    font-family: "Roboto";
    font-weight: 700;
    line-height: 19px;
  }
  .introjs-tooltiptext {
    font-size: 13px;
    color: #495057;
    padding: 16px !important;
    letter-spacing: 0.65px;
    font-family: "Roboto";
    line-height: 18px;
  }
  .introjs-button:focus {
    box-shadow: none;
  }
  .introjs-skipbutton {
    color: #ced4da;
    font-weight: 300;
    padding-bottom: 0 !important;
    font-size: 28px;
    padding: 0;
    line-height: 1;
  }
  .introjs-tooltip-header {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 20px;
  }
  // .introjs-arrow.top-middle {
  //   top: -13px;
  //   left: auto;
  //   margin-left: -5px;
  //   border-left: 8px solid transparent;
  //   border-right: 8px solid transparent;
  //   border-bottom: 8px solid #1864ab;
  //   right: 20px;
  // }
  // .introjs-arrow {
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     border-left: 7px solid transparent;
  //     border-right: 8px solid transparent;
  //     border-bottom: 9px solid #fff;
  //     right: -8px;
  //   }
  // }
  .btnPart {
    padding: 0px;
    position: absolute;
    left: 55%;
    bottom: 25px;
    a {
      display: inline-block;
      padding: 0.5rem 1rem;
      border: 1px solid #bdbdbd;
      font-size: 14px;
      color: #424242;
      cursor: pointer;
      border-radius: 0.2em;
      &.intro-skip {
        border: 1px solid #ced4da;
        color: #495057;
      }
      &.intro-start {
        border: 1px solid #1864ab;
        background-color: #1864ab;
        color: #fff;
      }
    }
  }
  .introjs-tooltipbuttons {
    padding-top: 10px;
  }
  .introjs-nextbutton {
    font-size: 0px;
    position: relative;
    &::after {
      content: "Okay";
      font-size: 14px;
    }
  }
  .introjs-tooltipbuttons {
    a {
      padding: 0.5rem 1rem 0rem 1rem;
    }
  }
}

.introjs-tooltipReferenceLayer.introjs-fixedTooltip {
  width: 190px !important;
}
.ng-tns-c3-0.introjs-showElement.introjs-relativePosition,
.mat-list-item-content .ng-tns-c3-0 {
  display: flex;
  justify-content: space-between;
}

.introjs-fixedTooltip {
  height: 48px !important;
  // top: 130px !important;
  width: 188px !important;
  left: 0 !important;
  margin-top: -10px;
}

// #clean_request {
//   box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 2px,
//     rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px;
//   opacity: 1;
//   width: 135.1px !important;
//   height: 26.8px !important;
//   top: 101.4px !important;
//   left: 150px !important;
//   background-color: #cc0000 !important;
// }

.introjs-helperLayer {
  box-shadow: #1864ab 0px 0px 1px 2px, #21212180 0px 0px 0px 5000px !important;
  z-index: 1 !important;
}

// span#add_guest {
//   position: relative !important;
//   z-index: 9999999999 !important;
// }

// .introjs-disableInteraction {
//   z-index: -1 !important;
//   position: absolute !important;
// }
// .introjs-overlay {
//   z-index: 0 !important;
// }
// .introjs-helperLayer {
//     box-shadow: #1864ab 0px 0px 1px 2px, #21212180 0px 0px 0px 5000px !important;
//     z-index: 0 !important;
// }
